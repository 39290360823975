const colors = {
  linkColor: '#0099ff',
  btnColor: '#0099ff',
  btnTextColor: '#ffffff',
  backgroundColor: '#ffffff',
};

const configs = {
  sidebarWidth: 240,
  logo: null,
  logoHeight: 36,
};

const muiConfigs = {
  typography: {
    allVariants: {
      color: '#666',
    },
  },
  palette: {
    primary: {
      main: colors.linkColor,
    },
    // secondary: {
    //   main: colors.secondaryMain,
    //   textColor: '#ffffff',
    // },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.backgroundColor,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // backgroundColor: colors.backgroundColor,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableHead-root .MuiTableCell-root': {
            color: '#ffffff',
            backgroundColor: '#aaa',
            borderRight: '1px solid #eee',
          },
          '& .MuiTableHead-root .MuiTableCell-root:last-child': {
            borderRight: 'none',
          },
          '& .MuiTableBody-root .MuiTableCell-root': {
            color: '#666',
          },
          '& .MuiTableCell-root': {
            padding: '12px 16px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-contained': {
            color: colors.btnTextColor,
            backgroundColor: colors.btnColor,
          },
          fontSize: '0.8rem',
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  // #666
};

export default muiConfigs;
export { colors, configs };
