import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Provider as MainProvider } from './FormContext';
import AlertError from '../State/AlertError';
import TranslationsContext from '../../Container/Language/TranslationsContext';

const FormProvider = ({
  children,
  sendRequest,
  formName,
  Provider: PersonalProvider,
  ...other
}) => {
  const { t } = useContext(TranslationsContext);
  const [form, setForm] = useState({
    formLoading: false,
    formErrors: null,
    formData: null,
  });

  const clearData = () => setForm({
    formLoading: false,
    formErrors: null,
    formData: null,
  });

  const sendForm = (data, opts, callback) => {
    setForm({
      formLoading: true,
      formErrors: null,
      formData: null,
    });

    sendRequest(formName, { ...opts || {}, body: data }).then((response) => {
      const formResponse = {
        formLoading: false,
        formErrors: null,
        formData: null,
      };

      if (response.success) {
        // const message = t(`toast.${formName}`);
        // if (message && message.substring(0, 6) !== 'toast.') addToast(message);

        formResponse.formData = response || { success: true };
      } else if (response.errors) {
        formResponse.formErrors = response.errors;
      } else {
        formResponse.formData = response;
      }
      setForm(formResponse);

      if (typeof callback === 'function') {
        callback(response);
      }
    });
  };

  const { formLoading, formErrors, formData } = form;
  const Provider = PersonalProvider || MainProvider;

  return (
    <Provider
      value={{
        ...other,
        formLoading,
        formErrors,
        formData,
        sendForm,
        sendRequest,
        clearData,
      }}
    >
      <AlertError error={formErrors && formErrors.access ? t('app:notify:operation:forbidden') : null} />

      {children}
    </Provider>
  );
};

FormProvider.propTypes = {
  children: PropTypes.node,
  formName: PropTypes.string.isRequired,
  sendRequest: PropTypes.func.isRequired,
  Provider: PropTypes.instanceOf(Object),
};

FormProvider.defaultProps = {
  children: null,
  Provider: null,
};

export default FormProvider;
