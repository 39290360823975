export default {
  apiUrl: `${window.location.protocol}//${window.location.hostname}`,
  url: `${window.location.protocol}//${window.location.hostname}`,
  prefix: process.env.REACT_APP_PREFIX,
  socketio: process.env.REACT_APP_SOCKETIO_BASE_URL,
  recaptcha: process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY,
  token: process.env.REACT_APP_TOKEN,
  path: process.env.BASE_PATH,
  ga: process.env.REACT_APP_GA_TRACKING_ID,
};
