import React, { useContext } from 'react';
import { v4 } from 'uuid';
import _ from 'lodash';

import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import TranslationsContext from '../../Container/Language/TranslationsContext';
import AuthContext from '../../HO/Auth/AuthContext';
import ConfigsContext from '../../Container/Config/ConfigsContext';
import { MenuBottomConsumer, MenuBottomProvider } from '../../Container/Menu';
import LayoutConfigContext from './LayoutConfigContext';
import conf from '../../../configs';

const Copyright = () => {
  const { user } = useContext(AuthContext);
  const { t } = useContext(TranslationsContext);
  const { data: configs } = useContext(ConfigsContext);

  const d = new Date();
  let site;
  let year = 2008;

  switch (Number(user?.site)) {
    case 1:
      year = 2009;
      site = t('app:copyright:smsclub');
      break;
    case 3:
      site = t('app:copyright:alphasms');
      break;
    default:
      site = configs ? configs.webshop_name : '';
  }

  return (
    <Divider>
      <Typography variant="body2" gutterBottom>
        Copyright
        {' '}
        &copy;
        {' '}
        {year}
        -
        {d.getFullYear()}
        {' '}
        {site}
      </Typography>
    </Divider>
  );
};

const Footer = ({ data }) => {
  const theme = useTheme();
  const isDownThanMD = useMediaQuery(theme.breakpoints.down('md'));
  const { form: { language } } = useContext(TranslationsContext);
  const { configs } = useContext(LayoutConfigContext);
  const sx = { mt: 4 };
  if (isDownThanMD) {
    sx.bgcolor = 'white';
    sx.pb = 2;
    sx['& p'] = { fontSize: '12px' };
    sx['& span'] = { fontSize: '14px' };
    sx['& .MuiGrid-item'] = { pl: 0 };
    sx['& .MuiGrid-item:first-child'] = { ml: 2 };
  } else {
    sx.mx = 2;
  }

  return (
    <Box sx={sx}>
      {isDownThanMD ? (configs.logo ? (
        <>
          <img
            alt="logo"
            src={`${conf.url || ''}${configs.logo}`}
            style={{
              padding: '24px 16px',
              maxHeight: '128px',
              maxWidth: '128px',
            }}
          />
          {data && data.length ? (<Divider />) : null}
        </>
      ) : null) : (<Copyright />)}

      <Grid container justifyContent="center" spacing={3}>
        {_.map(data, (menu) => (
          <Grid item key={v4()}>
            <List>
              <ListItem disablePadding>
                <ListItemButton component="a" href={menu.url}>
                  <ListItemText align="center" primary={menu[`name_${language}`]} />
                </ListItemButton>
              </ListItem>
              {_.map(menu.children || [], (sub) => (
                <ListItem disablePadding key={v4()}>
                  <ListItemButton component="a" href={sub.url}>
                    <ListItemText align="center" secondary={sub[`name_${language}`]} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>

      {isDownThanMD ? (<Copyright />) : null}
    </Box>
  );
};

const FooterContext = (props) => (
  <MenuBottomProvider form={{}}>
    <MenuBottomConsumer>
      {(state) => (<Footer {...props} {...state} />)}
    </MenuBottomConsumer>
  </MenuBottomProvider>
);

export default FooterContext;
