import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import {
  Box,
  CircularProgress,
  CssBaseline,
  useMediaQuery,
} from '@mui/material';

import { AuthConsumer } from '../../HO/Auth';
import ConfigsContext from '../../Container/Config/ConfigsContext';
// import { ToastsProvider } from '../../HO/Toasts'; use and move alert
import TranslationsContext from '../../Container/Language/TranslationsContext';
import { TranslationsProvider } from '../../Container/Language';
import { Provider } from './LayoutConfigContext';
import muiConfigs, { colors, configs } from './muiConfigs';

import Sidebar from './Sidebar';
import TopBar from './TopBar';
import Body from './Body';
import Footer from './Footer';

import HelpcrunchChat from './HelpcrunchChat';
import FeedBack from './FeedBack';

const Layout = ({
  children,
  pageConfigs,
  setPageConfigs,
  colors: col,
}) => {
  const location = useLocation();
  const { data: conf } = useContext(ConfigsContext);
  const { t } = useContext(TranslationsContext);
  const theme = useTheme();
  const downThenMD = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(!downThenMD);

  // cleanup
  useEffect(() => () => setPageConfigs({}), [setPageConfigs, location]);

  useEffect(() => {
    document.title = `${(pageConfigs.name ? `${pageConfigs.name} - ${t('app:title')}` : t('app:title'))} - ${conf.webshop_name}`;
  }, [pageConfigs, conf, t]);

  return (
    <AuthConsumer>
      {({ user }) => (
        <Box
          sx={user ? { display: 'flex' } : {}}
          style={{ backgroundColor: col.backgroundColor, minHeight: '100vh' }}
        >
          <CssBaseline />
          <HelpcrunchChat />
          <TopBar {...pageConfigs} open={open} setOpen={setOpen} />

          {user ? (
            <>
              <FeedBack />
              <Sidebar {...pageConfigs} open={open} setOpen={setOpen} />

              <Body {...pageConfigs} open={open}>
                <Box style={{ minHeight: '50vh' }} sx={{ padding: theme.spacing(3) }}>
                  {children}
                </Box>

                <Footer />
              </Body>
            </>
          ) : (
            <>
              <Box style={{ minHeight: '50vh' }} sx={{ padding: theme.spacing(3) }}>
                {children}
              </Box>

              <Footer />
            </>
          )}
        </Box>
      )}
    </AuthConsumer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const LayoutContext = (props) => {
  const { data } = useContext(ConfigsContext);
  const [pageConfigs, setPageConfigs] = useState({});
  const configsNew = { ...configs };
  const colorsNew = { ...colors };

  if (data.themeLinkColor) {
    muiConfigs.palette.primary.main = data.themeLinkColor;
    colorsNew.linkColor = data.themeLinkColor;
  }
  if (data.themeBackgroundColor) {
    muiConfigs.components.MuiAppBar.styleOverrides.root.backgroundColor = data.themeBackgroundColor;
    colorsNew.backgroundColor = data.themeBackgroundColor;
  }
  if (data.themeBtnColor) {
    muiConfigs.components.MuiButton.styleOverrides.root['&.MuiButton-contained'].backgroundColor = data.themeBtnColor;
    colorsNew.btnColor = data.themeBtnColor;
  }
  if (data.themeBtnTextColor) {
    muiConfigs.components.MuiButton.styleOverrides.root['&.MuiButton-contained'].color = data.themeBtnTextColor;
    colorsNew.btnTextColor = data.themeBtnTextColor;
  }
  if (data.themeLogoHeight && Number(data.themeLogoHeight) > 1) {
    configsNew.logoHeight = Number(data.themeLogoHeight);
  }
  if (data.themeLogo) {
    configsNew.logo = data.themeLogo;
  }

  useEffect(() => {
    if (data?.themeFavicon) {
      const link = document.querySelector('link[rel="icon"][type="image/x-icon"]');
      link.href = data.themeFavicon;
    }
  }, [data]);

  return (
    <Provider
      value={{
        pageConfigs,
        setPageConfigs,
        colors: colorsNew,
        configs: configsNew,
      }}
    >
      <ThemeProvider theme={createTheme(muiConfigs)}>
        <Layout
          {...props}
          pageConfigs={pageConfigs}
          setPageConfigs={setPageConfigs}
          colors={colorsNew}
        />
      </ThemeProvider>
    </Provider>
  );
};

export default (props) => {
  const { data, dataLoading } = useContext(ConfigsContext);

  if (!data || dataLoading) {
    return (
      <div style={{ margin: '25px auto', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <AuthConsumer>
      {({ user }) => (
        <TranslationsProvider form={{ language: user ? user.language : data.language }}>
          <LayoutContext {...props} />
        </TranslationsProvider>
      )}
    </AuthConsumer>
  );
};
